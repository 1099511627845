import { IItem } from '@pickles/shared/models';
import colors from '@pickles/shared/utils/colors';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import useScreenSize from '../../hooks/useScreenSize';
import { ItemCard } from '../ItemCard';
import SvgArrow from '../Shared/SvgArrow';
import {
  VehicleListGroup,
  VehiclesListButtonLeft,
  VehiclesListButtonPanel,
  VehiclesListButtonRight,
  VehiclesListContainer,
  VehiclesListHeader,
  VehiclesListTitle,
} from './VehiclesList.style';

export interface VehiclesListProps {
  title?: string;
  data: IItem.Item[];
  itemCountPerGroup: number;
  rowCountPerGroup: number;
  customTitleComponent?: ReactNode;
}

export const VehiclesList: FC<VehiclesListProps> = ({
  title,
  data,
  itemCountPerGroup,
  rowCountPerGroup,
  customTitleComponent,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [leftButtonAvailable, setLeftButtonAvailable] = useState(false);
  const [rightButtonAvailable, setRightButtonAvailable] = useState(true);
  const { screenSize, isMobile } = useScreenSize();

  const computedItems = useMemo(() => {
    const vehicles: IItem.Item[][] = [];

    data.forEach((item, i) => {
      const index = Math.floor(i / itemCountPerGroup);
      if (vehicles[index] && vehicles.length) {
        vehicles[index].push(item);
      } else {
        vehicles[index] = [item];
      }
    });

    return vehicles;
  }, [data, itemCountPerGroup]);

  const next = () => {
    if (rightButtonAvailable) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prev = () => {
    if (leftButtonAvailable) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    setLeftButtonAvailable(currentSlide > 0);
    setRightButtonAvailable(currentSlide < Math.floor(data.length / itemCountPerGroup));
  }, [currentSlide, data, itemCountPerGroup, screenSize]);

  const vehicles: IItem.Item[][] = [];
  data.forEach((item, i) => {
    const index = Math.floor(i / 2);
    if (vehicles[index] && vehicles.length) {
      vehicles[index].push(item);
    } else {
      vehicles[index] = [item];
    }
  });

  return (
    <VehiclesListContainer>
      <VehiclesListHeader>
        {customTitleComponent ? (
          customTitleComponent
        ) : (
          <>
            <VehiclesListTitle>{title}</VehiclesListTitle>
            <VehiclesListButtonPanel>
              <VehiclesListButtonLeft onClick={prev} disabled={!leftButtonAvailable}>
                <SvgArrow
                  color={leftButtonAvailable ? colors.blumine_main_blue : colors.bali_hai_grey}
                />
              </VehiclesListButtonLeft>
              <VehiclesListButtonRight onClick={next} disabled={!rightButtonAvailable}>
                <SvgArrow
                  color={rightButtonAvailable ? colors.blumine_main_blue : colors.bali_hai_grey}
                />
              </VehiclesListButtonRight>
            </VehiclesListButtonPanel>
          </>
        )}
      </VehiclesListHeader>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={isMobile}
        selectedItem={currentSlide}
        renderIndicator={() => null}
        onChange={(index) => setCurrentSlide(index)}
      >
        {computedItems &&
          computedItems.map((vehicles, index) => (
            <VehicleListGroup key={index} gridFr={itemCountPerGroup / rowCountPerGroup}>
              {vehicles.map((item) => (
                <ItemCard key={item.id} item={item} />
              ))}
            </VehicleListGroup>
          ))}
      </Carousel>
    </VehiclesListContainer>
  );
};
