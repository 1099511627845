import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import reactMarkdown from 'react-markdown';
import styled from 'styled-components';
import sizes from '../../../util/sizes';
import remarkBreaks from 'remark-breaks';

interface StyledReactMarkdownProps {
  text: string;
}

const StyledReactMarkdown: FC<StyledReactMarkdownProps> = ({ text }) => {
  return <ReactMarkdownWrapper remarkPlugins={[remarkBreaks]}>{text}</ReactMarkdownWrapper>;
};

export default StyledReactMarkdown;

const ReactMarkdownWrapper = styled(reactMarkdown)`
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: ${colors.san_juan_grey};
  max-width: ${sizes.XL};

  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 12px;
    line-height: 150%;
  }

  > h1 {
    font-size: 36px;
  }

  > h2 {
    font-size: 24px;
  }

  > ul {
    list-style: outside;
    list-style-type: disc;

    li {
      line-height: 150%;
      margin-left: 1em;
      padding-left: 0.5em;
    }
  }

  > ol {
    list-style: outside;
    list-style-type: decimal;

    li {
      line-height: 150%;
      margin-left: 1em;
      padding-left: 0.5em;
    }
  }

  a {
    color: ${colors.cerulean_blue};
    font-weight: 500;
    overflow-wrap: anywhere;
  }

  p:not(:first-child) {
    margin-top: 22px;
  }
`;
