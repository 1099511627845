import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../util/sizes';

export const VehiclesListContainer = styled.div`
  width: 100%;

  /* @media (min-width: ${sizes.XL}) {
    .slider {
      li:not(:nth-child(3n)) {
        margin-right: 30px;
      }
      .slide {
        min-width: calc(33.33% - 20px);
        img {
          border-radius: 6px;
        }
      }
    }
  }

  @media (max-width: ${sizes.XL}) and (min-width: ${sizes.S}) {
    li:nth-child(even) {
      margin-right: 0;
    }
    li:nth-child(odd) {
      margin-right: 40px;
    }
    .slider {
      .slide {
        min-width: calc(50% - 20px);
      }
    }
  }

  @media (max-width: ${sizes.S}) {
    .slider {
      .slide {
        margin-right: 20px;
        min-width: calc(100% - 20px);
      }
    }
    margin-top: 20px;
  } */
`;

export const VehiclesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const VehiclesListTitle = styled.div`
  font-family: BasierCircle;
  font-weight: 600;
  font-size: 32px;
  color: ${colors.rhino_black};

  @media (max-width: ${sizes.S}) {
    font-size: 20px;
  }
`;

export const VehiclesListButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VehiclesListButtonLeft = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const VehiclesListButtonRight = styled.button`
  border: 0;
  background-color: transparent;
  transform: scale(-1, 1);
  cursor: pointer;
`;

interface VehicleListGroupProps {
  gridFr: number;
}

export const VehicleListGroup = styled.div<VehicleListGroupProps>`
  display: grid;
  grid-template-columns: repeat(${({ gridFr }) => gridFr ?? 1}, 1fr);
  column-gap: 30px;
  row-gap: 47px;
  max-width: fit-content;
  margin: 0 auto;
`;
