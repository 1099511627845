import { useRouter } from 'next/router';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import colors from '@pickles/shared/utils/colors';

import Button from '../Shared/Button/Button';
import StyledReactMarkdown from '../Shared/StyledReactMarkdown';
import { Flex } from '../Shared/Layout/Flex';

import sizes from '../../util/sizes';

interface DescriptionProps {
  info: any;
  enableBackground?: boolean;
}

const Description: FC<DescriptionProps> = ({ info, enableBackground = true }) => {
  const router = useRouter();

  return (
    <>
      {info && (
        <DescriptionContainer showBackground={enableBackground}>
          <DescriptionBlock>
            <DescriptionBlockLeftContainer>
              <StyledReactMarkdown text={info.leftBlock} />
            </DescriptionBlockLeftContainer>

            <DescriptionBlockRightContainer>
              <StyledReactMarkdown text={info.rightBlock} />

              <Flex margin={'20px 0 0 0'}>
                {info.buttonLink && info.buttonText && (
                  <Button
                    onBtnClick={() => router.push(info.buttonLink)}
                    text={info.buttonText}
                    type="button"
                    width="320px"
                    height="52"
                  />
                )}
              </Flex>
            </DescriptionBlockRightContainer>
          </DescriptionBlock>
        </DescriptionContainer>
      )}
    </>
  );
};

export default Description;

const DescriptionContainer = styled.div<{ showBackground: boolean }>`
  max-width: ${sizes.XL};
  padding: 56px 0 80px;

  ${(props) =>
    props.showBackground &&
    css`
      background-color: ${colors.zircon_light_blue};
      box-shadow: 0 0 0 100vmax ${colors.zircon_light_blue};
      clip-path: inset(0 -100vmax);
    `};

  @media (max-width: ${sizes.S}) {
    margin-top: 0;
    padding: 20px 0 0 0;
  }
`;

const DescriptionBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: ${sizes.XL};

  > :not(:first-child) {
    margin-left: 80px;
  }

  @media (max-width: ${sizes.M}) {
    flex-direction: column;

    > :not(:first-child) {
      margin-top: 40px;
      margin-left: 0;
    }
  }
`;

const DescriptionBlockLeftContainer = styled.div`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  max-width: ${sizes.XL};
`;

const DescriptionBlockRightContainer = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: 'center';
  flex-direction: column;
  max-width: ${sizes.XL};
`;
