import React from 'react';

interface ISvgArrow {
  color?: string;
}

const SvgArrow = ({ color = '#8393A8' }: ISvgArrow) => {
  return (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5833 1.83301L1.41659 10.9997L10.5833 20.1663"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgArrow;
