import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../util/sizes';
import { ClickButton } from '../Shared/styles';

export const UpcomingAuctionsContainer = styled.div`
  max-width: 1170px;
  margin-top: 60px;
  margin-bottom: 94px;
  width: 100%;

  @media (max-width: ${sizes.S}) {
    margin-top: 0;
    margin-bottom: 25px;
  }
`;

export const UpcomingAuctionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${sizes.S}) {
    margin-bottom: 12px;
    margin-top: 28px;
  }

  /* @media (max-width: ${sizes.XS}) {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 10px;
    button {
      padding: 10px 0 0 0;
    }
  } */
`;

export const UpcomingAuctionsTitle = styled.h2`
  font-family: BasierCircle;
  font-weight: 600;
  font-size: 32px;
  color: ${colors.rhino_black};

  @media (max-width: ${sizes.M}) {
    font-size: 25px;
    line-height: 26px;
  }

  @media (max-width: ${sizes.S}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const ViewAllButton = styled(ClickButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border: 0px;
  }
`;

export const ViewAllButtonText = styled.div`
  font-family: BasierCircle-SemiBold;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.blumine_main_blue};
  margin-right: 10px;

  @media (max-width: ${sizes.S}) {
    font-size: 16px;
  }
`;

export const UpcomingAuctionsHolder = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;

  @media (max-width: ${sizes.XL}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 1fr;
  }
`;

export const EmptyContentWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const EmptyText = styled.div`
  color: ${colors.rhino_black};
  font-family: BasierCircle-Medium;
  margin: 20px 0;
`;
