import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { map } from 'lodash';

import { DashboardHooks, LoaderHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import Loading from '../Shared/Loading';
import { ButtonArrow } from '../Shared/Icons';
import AuctionCard from '../AuctionCard';
import eBidsIcon from '../../public/images/emptyEbids.png';

import { LoadingWrapper } from '../SearchContent/style';
import {
  UpcomingAuctionsContainer,
  UpcomingAuctionsHeader,
  UpcomingAuctionsHolder,
  UpcomingAuctionsTitle,
  ViewAllButton,
  ViewAllButtonText,
  EmptyContentWrapper,
  EmptyText,
} from './UpcomingAuctions.style';
import { IAuction } from '@pickles/shared/models';

interface UpcomingAuctionsProps {
  auctionFilter?: (auction: IAuction.Auction) => void;
  viewAllRoute?: string;
}

export const UpcomingAuctions: FC<UpcomingAuctionsProps> = ({
  auctionFilter = (auction) => auction,
  viewAllRoute,
}) => {
  const router = useRouter();
  const { maxWidth } = useScreenSize();
  const { t } = useTranslation();
  const sortedAuctions = DashboardHooks.useSortedAuctions();
  const isLoading = LoaderHooks.useLoadingIndicator('dashboard');

  const auctions = useMemo(() => {
    const slicedAuctions = sortedAuctions.filter(auctionFilter).slice(0, 6);
    return map(slicedAuctions, (auction) => <AuctionCard key={auction.id} auction={auction} />);
  }, [sortedAuctions]);

  const handleViewAllClick = () => {
    router.push(viewAllRoute || '/auctions');
  };

  const renderUpcomingAuctions = () => {
    if (auctions?.length) {
      return <UpcomingAuctionsHolder>{auctions}</UpcomingAuctionsHolder>;
    }

    return (
      <EmptyContentWrapper>
        <Image src={eBidsIcon} alt="Empty Icon" />
        <EmptyText>{t('labels:no_auctions')}</EmptyText>
      </EmptyContentWrapper>
    );
  };

  return (
    <UpcomingAuctionsContainer>
      <UpcomingAuctionsHeader>
        <UpcomingAuctionsTitle>{t('labels:latest_auctions')}</UpcomingAuctionsTitle>
        <ViewAllButton onClick={handleViewAllClick}>
          {maxWidth.m ? (
            <ViewAllButtonText id="view_all">{t('buttons:view_all')}</ViewAllButtonText>
          ) : (
            <>
              <ViewAllButtonText id="view_all_auctions">
                {t('buttons:view_all_auctions')}
              </ViewAllButtonText>
              <ButtonArrow />
            </>
          )}
        </ViewAllButton>
      </UpcomingAuctionsHeader>

      {isLoading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        renderUpcomingAuctions()
      )}
    </UpcomingAuctionsContainer>
  );
};
