import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../util/sizes';

interface IDownloadImgLinWrapper {
  isAppStore?: boolean;
}

interface HelperProps {
  backgroundColor?: string;
  sideMargin?: string;
}

export const DownloadContentWrapper = styled.div`
  margin-top: 84px;
  display: flex;
  margin-bottom: 84px;
  max-width: ${sizes.XL};
  @media (max-width: ${sizes.M}) {
    flex-direction: column;
  }
  @media (max-width: ${sizes.S}) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const AppImageWrapper = styled.div`
  margin-right: 130px;
  width: 570px;
  max-width: ${sizes.XL};

  flex-shrink: 0;
  @media (max-width: ${sizes.XL}) {
    max-width: 50%;
    margin-right: 40px;
  }
  @media (max-width: ${sizes.M}) {
    margin: 44px auto;
    max-width: 100%;
  }
  @media (max-width: ${sizes.S}) {
    max-width: 100%;
  }
`;

export const DownloadTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${sizes.XL};
`;

export const DownloadOurAppText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 36px;
  color: ${colors.rhino_black};
  margin-bottom: 25px;
  max-width: ${sizes.XL};
  line-height: 130%;

  @media (max-width: ${sizes.S}) {
    font-size: 28px;
    margin-bottom: 16px;
  }
`;

export const BiddingLiveText = styled.div`
  font-family: BasierCircle;
  font-size: 17px;
  color: ${colors.san_juan_grey};
  margin-bottom: 42px;
  max-width: ${sizes.XL};
  line-height: 26px;
  @media (max-width: ${sizes.S}) {
    margin-bottom: 0;
  }
`;
export const ChoosingPlatformText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 24px;
  color: ${colors.rhino_black};
  margin-bottom: 20px;
  max-width: ${sizes.XL};
  line-height: 150%;

  @media (max-width: ${sizes.S}) {
    text-align: center;
  }
`;

export const DownloadImagesWrapper = styled.div`
  display: flex;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.S}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DownloadImgLinWrapper = styled.div<IDownloadImgLinWrapper>`
  margin-right: ${({ isAppStore = false }) => (isAppStore ? '17px' : '0')};
  cursor: pointer;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.S}) {
    margin: 0;
    margin-bottom: ${({ isAppStore = false }) => (isAppStore ? '17px' : '0')};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${colors.mischka};
  margin: 84px 0;
  max-width: ${sizes.XL};
`;

export const QrBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 112px;
  gap: 90px;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.S}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ScanQrCodeText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 36px;
  color: ${colors.rhino_black};
  width: 510px;
  max-width: ${sizes.XL};
  line-height: 130%;

  @media (max-width: ${sizes.S}) {
    font-size: 28px;
    text-align: center;
    margin-right: 0;
    width: 100%;
    margin-bottom: 44px;
  }
`;

export const QrCodeWrapper = styled.div`
  padding: 16px;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  max-width: ${sizes.XL};
`;
